import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  loading: false,
  error: null,
  categories: [],
  products: [],
};

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    loadCategoryRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    loadCategorySuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.categories = action.payload?.result;
    },
    loadCategoryFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadProductRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    loadProductSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.products = action.payload?.result;
    },
    loadProductFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadLandingOfferProductsRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    loadLandingOfferProductsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.landingOfferProducts = action?.payload?.result;
    },
    loadLandingOfferProductsFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadAdvertisementsRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    loadAdvertisementsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.advertisements = action.payload;
    },
    loadAdvertisementsFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadCategoryRequested,
  loadCategorySuccess,
  loadCategoryFail,
  loadProductRequested,
  loadProductSuccess,
  loadProductFail,
  loadLandingOfferProductsRequested,
  loadLandingOfferProductsSuccess,
  loadLandingOfferProductsFail,
  loadAdvertisementsRequested,
  loadAdvertisementsSuccess,
  loadAdvertisementsFail,
} = productSlice.actions;

export default productSlice.reducer;
