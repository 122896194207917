import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loadUserRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    loadUserSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.user = action.payload;
    },
    loadUserFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { loadUserRequested, loadUserSuccess, loadUserFail } = userSlice.actions;

export default userSlice.reducer;
