import { Button, Input } from "components/shared";
import React, { useContext, useState } from "react";
import Wings from "assets/images/login/Wings.jpg";
import { useNavigate } from "react-router-dom";
import { appContext } from "layouts";
import { withFormik, FormikProps } from "formik";
import * as Yup from "yup";

interface ISignUpProps {
  onSubmit?: any;
  setAuthOpenQuery?: any;
}

interface IFormValue {
  firstname: string;
  lastname: string;
  password: string;
  email: string;
  mobile: string;
}

function SignUp(props: FormikProps<IFormValue> & ISignUpProps) {
  const { setSignupOpen, setLoginOpen }: any = useContext(appContext);
  const [showPassword, setShowPassword] = useState(false);

  const visiblePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="h-fit w-[60vw] px-10 py-3">
      <div className="text-[50px] text-black font-poppinsMedium text-center mb-4">
        Hello there!
      </div>
      <div className="flex gap-8 justify-between items-center">
        <div className="w-[50%] h-[50%]">
          <img src={Wings} alt="login" />
        </div>

        <div className="space-y-3 w-[50%]">
          <form
            onSubmit={props.handleSubmit}
            className="w-full max-w-md space-y-2"
          >
            <div className="w-full space-y-2">
              <Input
                name="email"
                type="text"
                placeholder="Enter your Email"
                values={props.values}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                errors={props.errors}
                touches={props.touched}
              />
              <Input
                name="firstname"
                type="text"
                placeholder="Enter First Name"
                values={props.values}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                errors={props.errors}
                touches={props.touched}
              />
              <Input
                name="lastname"
                type="text"
                placeholder="Enter Last Name"
                values={props.values}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                errors={props.errors}
                touches={props.touched}
              />
              <Input
                name="mobile"
                type="text"
                placeholder="Enter Mobile Number"
                values={props.values}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                errors={props.errors}
                touches={props.touched}
              />
              <Input
                name="password"
                type="password"
                placeholder="Enter Password"
                values={props.values}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                errors={props.errors}
                touches={props.touched}
              />

              <Button
                title={props.isSubmitting ? "Signin In..." : "Signin"}
                type="submit"
                variant="large"
                onClick={() => {
                  setSignupOpen(false);
                }}
                disabled={props.isSubmitting}
              />
            </div>

            <div className="flex justify-end space-x-1">
              <div className="text-p3 text-end text-theme">
                Already have an account?
              </div>
              <div
                className="text-p3 text-end text-red-700 cursor-pointer"
                onClick={() => {
                  setTimeout(() => props.setAuthOpenQuery("login"), 300);
                }}
              >
                Login
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default withFormik<ISignUpProps, IFormValue>({
  validationSchema: Yup.object().shape({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    password: Yup.string().required("Password is required"),
    email: Yup.string().required("email is required"),
    mobile: Yup.string().required("mobile is required"),
  }),
  mapPropsToValues: () => ({
    firstname: "",
    lastname: "",
    password: "",
    email: "",
    mobile: "",
  }),
  handleSubmit: (values, { props, ...actions }) => {
    props.onSubmit(values, actions);
  },
})(SignUp);
