import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  loadReviewRequested,
  storeAddressRequested,
  storeGuestRequested,
  storeOrderRequested,
  updateGuestAddressRequested,
  updateOrderTypeRequested,
} from "store/order/orderSlice";

export function useStoreOrder() {
  const dispatch = useDispatch();
  const onStoreOrder = useCallback(
    async (values: any) => {
      await dispatch(storeOrderRequested(values));
    },
    [dispatch]
  );

  return { onStoreOrder };
}

export function useUpdateOrderType() {
  const dispatch = useDispatch();
  const onUpdateOrderType = useCallback(
    async (values: any) => {
      await dispatch(updateOrderTypeRequested(values));
    },
    [dispatch]
  );

  return { onUpdateOrderType };
}

export function useStoreGuest() {
  const dispatch = useDispatch();
  const onStoreGuest = useCallback(
    async (values: any) => {
      await dispatch(
        storeGuestRequested({
          firstName: values?.firstName,
          lastName: values?.lastName,
          email: values?.email,
          mobile: values?.mobile,
        })
      );
      await dispatch(
        storeAddressRequested({
          postalCode: values?.postalCode,
          street: values?.street,
          city: values?.city,
        })
      );
    },
    [dispatch]
  );
  return { onStoreGuest };
}

export function useUpdateAddress() {
  const dispatch = useDispatch();
  const onUpdateAddress = useCallback(
    async (values: any) => {
      await dispatch(storeAddressRequested(values));
    },
    [dispatch]
  );
  return { onUpdateAddress };
}

// export function useUpdateGuestAddress() {
//   const dispatch = useDispatch();
//   const onUpdateGuestAddress = useCallback(
//     async (values: any) => {
//       await dispatch(updateGuestAddressRequested(values));
//     },
//     [dispatch]
//   );

//   return { onUpdateGuestAddress };
// }

export function useLoadReview() {
  const dispatch = useDispatch();

  const onLoadReview = useCallback(async () => {
    await dispatch(loadReviewRequested({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadReview();
  }, [onLoadReview]);

  return { onLoadReview };
}
