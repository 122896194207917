import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  loading: false,
  error: null,
  orderType: {
    type: null,
    postalcode: null,
  },
  guest: {
    firstName: null,
    lastName: null,
    email: null,
    mobile: null,
  },

  address: {},
};

const orderSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    storeOrderRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    storeOrderSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.storeOrder = action.payload;
    },
    storeOrderFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateOrderTypeRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    updateOrderTypeSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.orderType = action.payload;
    },
    updateOrderTypeFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    storeGuestRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    storeGuestSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.guest = action.payload;
    },
    storeGuestFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    storeAddressRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    storeAddressSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.address = action.payload;
    },
    storeAddressFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateAddressRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    updateAddressSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.address = action.payload;
    },
    updateAddressFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateGuestAddressRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    updateGuestAddressSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.guest = action.payload;
    },
    updateGuestAddressFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadReviewRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    loadReviewSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.reviews = action.payload.results;
    },
    loadReviewFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  storeOrderRequested,
  storeOrderSuccess,
  storeOrderFail,
  updateOrderTypeRequested,
  updateOrderTypeSuccess,
  updateOrderTypeFail,
  storeGuestRequested,
  storeGuestSuccess,
  storeGuestFail,
  storeAddressRequested,
  storeAddressSuccess,
  storeAddressFail,
  updateAddressRequested,
  updateAddressSuccess,
  updateAddressFail,
  updateGuestAddressRequested,
  updateGuestAddressSuccess,
  updateGuestAddressFail,
  loadReviewRequested,
  loadReviewSuccess,
  loadReviewFail,
} = orderSlice.actions;

export default orderSlice.reducer;
