import React from "react";
export interface IButtonProps {
  title: string;
  type?: any;
  onClick?: any;
  image?: any;
  disabled?: boolean;
  variant?: "large" | "medium" | "small";
}

export const Button: React.FC<IButtonProps> = ({
  title,
  type = "submit",
  onClick,
  image,
  disabled,
  variant = "small",
}) => {
  const getVariantStyles = (variant: IButtonProps["variant"]) => {
    switch (variant) {
      case "large":
        return {
          size: "py-2 md:py-3 px-4 w-full",
          imageSize: "h-2.5 ",
        };
      case "medium":
        return {
          size: "py-2 md:py-3 px-4",
          imageSize: "h-2.5",
        };
      case "small":
        return {
          size: "py-1.5 sm:py-2 px-4",
          imageSize: "h-2.5",
        };
      default:
        return null;
    }
  };
  const variantStyles = getVariantStyles(variant);
  return (
    <button
      type={type}
      className={`${variantStyles?.size} rounded-md bg-red-600 lg:px-6 text-white text-p4 md:text-p3 tracking-widest font-bold uppercase flex items-center justify-center gap-2 h-fit`}
      onClick={onClick}
      disabled={disabled}
    >
      {image && (
        <img
          src={image}
          alt="button"
          className={`${variantStyles?.imageSize}`}
        />
      )}
      <span>{title}</span>
    </button>
  );
};
