import React from "react";
import { useNavigate } from "react-router-dom";

export const NavBar: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex items-center gap-4 mr-4 tracking-wider">
        <div className="cursor-pointer" onClick={() => navigate("/")}>
          HOME
        </div>
        <div className="cursor-pointer" onClick={() => navigate("/menu")}>
          MENU
        </div>
        <div className="cursor-pointer" onClick={() => navigate("/orders")}>
          ORDERS
        </div>
      </div>
    </div>
  );
};
