import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import {
  deleteCartRequested,
  loadCartRequested,
  storeCartRequested,
  updateCartRequested,
} from "store/cart/cartSlice";
import { isAuthorized as useAuthorized } from "store/auth/selector";

//load product to cart
export function useLoadCart() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);

  const onLoadCart = useCallback(async () => {
    await dispatch(loadCartRequested({ id: auth?.user?.id }));
  }, [dispatch]);

  useEffect(() => {
    onLoadCart();
  }, [onLoadCart]);

  return { onLoadCart };
}

//add product to cart
export function useStoreCart() {
  const dispatch = useDispatch();
  const isAuthorized = useSelector(useAuthorized);
  const { auth } = useSelector((state: RootState) => state.auth);

  const onStoreCart = useCallback(
    async (values: any) => {
      await dispatch(
        storeCartRequested(
          isAuthorized
            ? {
                userItems: {
                  userId: auth?.user?.id,
                  itemDetail: {
                    itemId: values?.id,
                    qty: 1,
                    description: "sample text",
                  },
                },
              }
            : values
        )
      );
    },
    [dispatch]
  );

  return { onStoreCart };
}

//add product to cart
export function useUpdateCart() {
  const dispatch = useDispatch();
  const isAuthorized = useSelector(useAuthorized);
  const { auth } = useSelector((state: RootState) => state.auth);

  const onUpdateCart = useCallback(
    async (id: any, type: any) => {
      await dispatch(
        updateCartRequested(
          isAuthorized
            ? {
                details: {
                  userItems: {
                    userId: auth?.user?.id,
                    itemDetail: {
                      itemId: id,
                      qty: type === "reduce" ? -1 : 1,
                      description: "sample text",
                    },
                  },
                },
                type: type,
              }
            : { id, type }
        )
      );
    },
    [dispatch]
  );

  return { onUpdateCart };
}

//remove product from cart
export function useDeleteCart() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);

  const onDeleteCart = useCallback(
    async (id: any) => {
      await dispatch(
        deleteCartRequested({
          userId: auth?.user?.id,
          itemIds: [id],
        })
      );
    },
    [dispatch]
  );

  return { onDeleteCart };
}
