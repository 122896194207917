import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useGoogleSignin } from "hooks/GoogleLogin";

const clientId =
  "772169924694-v5uom81obfppevqst49rcbtct0599kmc.apps.googleusercontent.com";

const App = () => {
  const { onGoogleSignin } = useGoogleSignin(); // Use the custom hook

  const handleLoginSuccess = (credentialResponse: any) => {
    const { credential } = credentialResponse;

    // Use the custom dispatch function from useGoogleSignin
    onGoogleSignin({ credential }, { setSubmitting: (status: boolean) => {} });
  };

  const handleLoginFailure = (error: void) => {
    console.error("Login Failed:", error);
  };
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className="App">
        <div>
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={handleLoginFailure}
          />
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default App;
