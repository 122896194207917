import React, { useContext } from "react";
import { appContext } from "layouts";
import { Button, Input } from "components/shared";
import Wings from "assets/images/login/Wings.jpg";
import { withFormik, FormikProps } from "formik";
import { string, object } from "yup";
import GoogleLogin from "../GoogleLogin";
import { useGoogleSignin } from "hooks/GoogleLogin";

interface ILoginProps {
  onSubmit?: any;
  setAuthOpenQuery?:any
}

interface IFormValue {
  username: string;
  password: string;
}

function Login(props: FormikProps<IFormValue> & ILoginProps) {
  const { onGoogleSignin } = useGoogleSignin();

  const { theme }: any = useContext(appContext);

  return (
    <div className="h-fit w-full md:w-[45vw] px-6 pb-6">
      <div
        className={`text-h3 font-poppinsMedium text-center mb-6 ${
          theme === "dark" ? "text-gray-50" : "text-gray-950"
        }`}
      >
        Welcome Back!
      </div>
      <div className="flex items-center space-x-10">
        <div className="w-[50%] h-[70%]">
          <img src={Wings} alt="login" />
        </div>
        <div className="w-[50%] space-y-2">
          <form
            onSubmit={props.handleSubmit}
            className="w-full max-w-md space-y-6"
          >
            <div className="w-full space-y-4">
              <div>
                <Input
                  name="username"
                  type="text"
                  placeholder="Enter Email"
                  values={props.values}
                  onChange={props.handleChange}
                  errors={props.errors}
                  touches={props.touched}
                />
              </div>
              <Input
                name="password"
                type="password"
                placeholder="Enter Password"
                values={props.values}
                onChange={props.handleChange}
                errors={props.errors}
                touches={props.touched}
              />
              <div className="text-p3 text-end text-theme">
                Forgot password?
              </div>

              <div className="flex justify-center w-full">
                <Button
                  title={props.isSubmitting ? "Logging In..." : "Login"}
                  type="submit"
                  variant="large"
                  disabled={props.isSubmitting}
                />
              </div>

              <div className="flex justify-end space-x-1">
                <div
                  className={`text-p3 text-end tracking-wider ${
                    theme === "dark" ? "text-gray-50" : "textgray-950"
                  }`}
                >
                  Don't have an account?
                </div>
                <div
                  className="text-p3 text-end text-red-700 font-poppinsSemiBold tracking-wider cursor-pointer"
                  onClick={() => {
                    setTimeout(() => props.setAuthOpenQuery("signup"), 100);
                  }}
                >
                  SignUp
                </div>
              </div>
            </div>
          </form>
          <GoogleLogin />
        </div>
      </div>
    </div>
  );
}

export default withFormik<ILoginProps, IFormValue>({
  validationSchema: object().shape({
    username: string().required("Username is required"),
    password: string().required("Password is required"),
  }),
  mapPropsToValues: () => ({
    username: "",
    password: "",
  }),
  handleSubmit: (values, { props, ...actions }) => {
    props.onSubmit(values, actions);
  },
})(Login);
