import { put, select, takeEvery, call } from "redux-saga/effects";
import { isAuthorized as useAuthorized } from "store/auth/selector";

import {
  storeOrderRequested,
  storeOrderSuccess,
  storeOrderFail,
  updateOrderTypeRequested,
  updateOrderTypeSuccess,
  updateOrderTypeFail,
  storeGuestRequested,
  storeGuestSuccess,
  storeGuestFail,
  storeAddressRequested,
  storeAddressSuccess,
  storeAddressFail,
  updateAddressRequested,
  updateAddressSuccess,
  updateAddressFail,
  updateGuestAddressRequested,
  updateGuestAddressSuccess,
  updateGuestAddressFail,
  loadReviewRequested,
  loadReviewSuccess,
  loadReviewFail,
} from "./orderSlice";
import { apiURL, axiosInstance } from "config";

export function* storeOrderEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const data = action.payload;
    yield put(storeOrderSuccess(data));
  } catch (error: any) {
    yield put(storeOrderFail(error.message));
  }
}

export function* updateOrderTypeEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const data = action.payload;
    yield put(updateOrderTypeSuccess(data));
  } catch (error: any) {
    yield put(updateOrderTypeFail(error.message));
  }
}

export function* storeGuestEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const data = action.payload;
    yield put(storeGuestSuccess(data));
  } catch (error: any) {
    yield put(storeGuestFail(error.message));
  }
}

export function* storeAddressEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    debugger;
    const data = action.payload;
    yield put(storeAddressSuccess(data));
  } catch (error: any) {
    yield put(storeAddressFail(error.message));
  }
}

export function* updateAddressEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    debugger;
    const data = action.payload;
    yield put(updateAddressSuccess(data));
  } catch (error: any) {
    yield put(updateAddressFail(error.message));
  }
}

export function* updateGuestAddressEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const isAuthorized = yield select(useAuthorized);
  const guest = yield select((state: any) => state.order.guest);
  try {
    if (isAuthorized) {
    } else {
      debugger;
      const data = action.payload;
      yield put(
        updateGuestAddressSuccess({
          ...guest,
          postalCode: data?.postalCode,
          street: data?.street,
          city: data?.city,
        })
      );
    }
  } catch (error: any) {
    yield put(updateGuestAddressFail(error.message));
  }
}

export function* loadReviewEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(axiosInstance.get, `${apiURL}/index-review`);
    yield put(loadReviewSuccess(data));
  } catch (error: any) {
    yield put(loadReviewFail(error.message));
  }
}

export function* orderSaga(): Generator<any, void, any> {
  yield takeEvery(storeOrderRequested, storeOrderEffect);
  yield takeEvery(updateOrderTypeRequested, updateOrderTypeEffect);
  yield takeEvery(storeGuestRequested, storeGuestEffect);
  yield takeEvery(storeAddressRequested, storeAddressEffect);
  yield takeEvery(updateAddressRequested, updateAddressEffect);
  yield takeEvery(updateGuestAddressRequested, updateGuestAddressEffect);
  yield takeEvery(loadReviewRequested, loadReviewEffect);
}
