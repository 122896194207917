import { takeLatest, put, call, takeEvery } from "redux-saga/effects";
import { axiosInstance, apiURL } from "config";
import toast from "react-hot-toast";
import { userInfo } from "constants/mock";
import { loadUserRequested, loadUserSuccess, loadUserFail } from "./userSlice";

//load user info
export function* loadUserEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    // const { data } = yield call(axiosInstance.get, `${apiURL}/products`);
    const data = userInfo;
    yield put(loadUserSuccess(data));
  } catch (error: any) {
    yield put(loadUserFail(error.message));
  }
}

export function* userSaga(): Generator<any, void, any> {
  yield takeEvery(loadUserRequested, loadUserEffect);
}
