import React, { useContext } from "react";
import { appContext } from "layouts/layout";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "components/shared";
import logo from "assets/images/header/logo.svg";
import visa from "assets/images/footer/visa-card.svg";
import master from "assets/images/footer/master-card.svg";
import american from "assets/images/footer/american-ex.svg";
import Subscription from "templates/Footer/Subscription";
import { useSubscription } from "hooks/Subscription";

export const Footer: React.FC = () => {
  const navigate = useNavigate();
  const { theme }: any = useContext(appContext);

  const {onSubscription} = useSubscription();
  return (
    <div
      className={`pb-2 px-4 md:px-12 blackBg ${
        theme === "dark" ? "blackBg" : "lightBg"
      }`}
    >
      <div className="w-full bg-pureBlack/10 backdrop-blur-sm border border-pureWhite/10 rounded-lg px-2 md:px-4 py-4 md:py-8 text-textGray space-y-6">
        <div className="flex flex-col md:flex-row md:justify-between gap-4">
          <div className="pt-2">
            <img src={logo} alt="logo" className="w-[8rem] md:w-[12rem]" />
          </div>
          <Subscription onSubmit={onSubscription} />
        </div>

        <div className="flex flex-col md:flex-row md:justify-between gap-4 text-p2 md:text-p1">
          <div className="flex flex-col gap-1">
            <div className="text-theme text-p1 font-poppinsSemiBold tracking-wide pb-2">
              About Us
            </div>
            <div className="cursor-pointer" onClick={() => navigate("/")}>
              Home
            </div>
            <div
              className="cursor-pointer"
              onClick={() => navigate("/contact-us")}
            >
              Contact Us
            </div>
            <div
              className="cursor-pointer"
              onClick={() => navigate("/restaurant")}
            >
              Our Restorent
            </div>
            <div
              className="cursor-pointer"
              onClick={() => navigate("/terms-and-conditions")}
            >
              Terms & Conditions
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-theme text-p1 font-poppinsSemiBold tracking-wide pb-2">
              Your Account
            </div>
            <div
              className="cursor-pointer"
              onClick={() => navigate("/profile")}
            >
              Personal Information
            </div>
            <div className="cursor-pointer" onClick={() => navigate("/orders")}>
              Track Your Order
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-theme text-p1 font-poppinsSemiBold tracking-wide pb-2">
              Categories
            </div>
            <div className="flex gap-12">
              <div className="flex flex-col gap-1">
                <div>Chicken & Spicy Wings</div>
                <div>Burger Deals</div>
                <div>Ribs</div>
                <div>Family Meals</div>
                <div>Wraps</div>
              </div>
              <div className="flex flex-col gap-1">
                <div>Fish & Chips</div>
                <div>Piri Piri</div>
                <div>Side Orders</div>
                <div>Kids Meals</div>
                <div>Extras</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <div className="text-theme text-p1 font-poppinsSemiBold tracking-wide">
              We Accept
            </div>
            <div className="flex gap-2">
              <img src={visa} alt="logo" className="w-[3rem]" />
              <img src={master} alt="logo" className="w-[3rem]" />
              <img src={american} alt="logo" className="w-[3rem]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
