import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  loading: false,
  error: null,
  storeCart: [],
  deleteCart: null,
  updateCart: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    loadCartRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    loadCartSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.cart = action.payload?.result?.itemDetails;
    },
    loadCartFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    storeCartRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    storeCartSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.storeCart = action.payload;
    },
    storeCartFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteCartRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    deleteCartSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.deleteCart = action.payload;
    },
    deleteCartFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateCartRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    updateCartSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.updateCart = action.payload;
    },
    updateCartFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadCartRequested,
  loadCartSuccess,
  loadCartFail,
  storeCartRequested,
  storeCartSuccess,
  storeCartFail,
  deleteCartRequested,
  deleteCartSuccess,
  deleteCartFail,
  updateCartRequested,
  updateCartSuccess,
  updateCartFail,
} = cartSlice.actions;

export default cartSlice.reducer;
