import React from "react";
import { Provider } from "react-redux";
import { ClientRouter } from "./router/Router";
import store from "store/store";
import { PrimeReactProvider } from "primereact/api";
import { Toaster } from "react-hot-toast";
import { SiTicktick } from "react-icons/si";
import { RiErrorWarningLine } from "react-icons/ri";

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PrimeReactProvider>
          <ClientRouter />
          <Toaster
            toastOptions={{
              success: {
                className:
                  "text-green-900 text-xs font-semibold tracking-widest bg-green-100 w-auto",
                icon: <SiTicktick className=" text-green-700  h-4 w-4" />,
                position: "top-right",
                duration: 8000,
              },
              error: {
                className:
                  "text-red-900 text-xs font-semibold tracking-widest bg-red-100 w-auto",
                icon: <RiErrorWarningLine className=" text-red-800  h-5 w-5" />,
                position: "top-right",
                duration: 8000,
              },
            }}
          />
        </PrimeReactProvider>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
