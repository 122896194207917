import { Outlet } from "react-router-dom";
import { Header, Footer } from "components/base";
import Headroom from "react-headroom";
import { createContext } from "react";
import { useState } from "react";

export const appContext = createContext(null);

export const ClientLayout: React.FC = () => {
  const [theme, setTheme] = useState<string>("dark");
  const [orderTypeOpen, setOrderTypeOpen] = useState<boolean>(false);

  return (
    <appContext.Provider
      value={
        {
          theme,
          setTheme,
          orderTypeOpen,
          setOrderTypeOpen,
        } as any
      }
    >
      <div
        className={`${theme} font-poppins tracking-wide text-textGray relative`}
      >
        <div className="absolute top-0 left-0 w-full z-50">
          <Headroom>
            <Header />
          </Headroom>
        </div>
        <div className={`${theme === "dark" ? "bg-black" : "bg-white"}`}>
          <Outlet />
        </div>
        <div className="">
          <Footer />
        </div>
      </div>
    </appContext.Provider>
  );
};
