import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  loadCategoryRequested,
  loadLandingOfferProductsRequested,
  loadProductRequested,
} from "store/products/productSlice";

// load categories
export function useLoadCategory() {
  const dispatch = useDispatch();

  const onLoadCategory = useCallback(async () => {
    await dispatch(loadCategoryRequested({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadCategory();
  }, [onLoadCategory]);

  return { onLoadCategory };
}

// load products
export function useLoadProduct() {
  const dispatch = useDispatch();

  const onLoadProduct = useCallback(async (values:any) => {
    await dispatch(loadProductRequested(values));
  }, [dispatch]);

  return { onLoadProduct };
}

// home page offer products
export function useLoadLandingOfferProducts() {
  const dispatch = useDispatch();

  const onLoadLandingOfferProducts = useCallback(async () => {
    await dispatch(loadLandingOfferProductsRequested({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadLandingOfferProducts();
  }, [onLoadLandingOfferProducts]);

  return { onLoadLandingOfferProducts };
}


export function useLoadAdvertisements() {
  const dispatch = useDispatch();

  const onLoadAdvertisements = useCallback(async () => {
    await dispatch(loadLandingOfferProductsRequested({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadAdvertisements();
  }, [onLoadAdvertisements]);

  return { onLoadAdvertisements };
}
