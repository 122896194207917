// src/store/rootSaga.ts
import { all } from "redux-saga/effects";
import { productSaga } from "./products/sagas";
import { authSaga } from "./auth/sagas";
import { cartSaga } from "./cart/sagas";
import { userSaga } from "./user/sagas";
import { orderSaga } from "./order/sagas";
import { subscriptionSaga } from "./subscription/sagas";

export function* rootSaga() {
  yield all([
    productSaga(),
    authSaga(),
    cartSaga(),
    userSaga(),
    orderSaga(),
    subscriptionSaga(),
  ]);
}
