import React from "react";
import { useNavigate } from "react-router-dom";
import * as routes from "constants/routes";
import ProfileLight from "assets/images/header/profile-light.svg";
import ProfileDark from "assets/images/header/profile-dark.svg";
import SignOutLight from "assets/images/header/signout-light.svg";
import SignOutDark from "assets/images/header/signout-dark.svg";
import SampleProfile from "assets/images/header/sample-profile.svg";
import SampleProfileDark from "assets/images/header/sample-profiledark.svg";
import { useLogin } from "hooks/Login";

interface IProfileMenu {
  theme: string;
  setOpen: any;
  data: any;
  setAuthOpen: any;
  setAuthOpenQuery: any;
}

export const ProfileMenu: React.FC<IProfileMenu> = ({
  theme,
  setOpen,
  data,
  setAuthOpen,
  setAuthOpenQuery,
}) => {
  const navigate = useNavigate();
  const { onLogin } = useLogin();

  const handleSignOut = () => {
    if (data !== null) {
      if (data.status) {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
      }
    } else {
      setAuthOpen(true);
      setAuthOpenQuery("login");
    }

    setOpen(false);
  };

  const userAvatar =
    data && data.user && data.user.avatar
      ? data.user.avatar
      : theme === "dark"
      ? SampleProfileDark
      : SampleProfile;

  const userName =
    data && data.user && data.user.firstname && data.user.lastname
      ? `${data.user.firstname} ${data.user.lastname}`
      : "Guest";
  const userEmail = data?.email || " ";

  return (
    <div className="flex flex-col border border-pureWhite/20 backdrop-blur-xl text-textGray rounded-md bg-pureBlack/90 min-w-[14rem]">
      <div className="flex items-center gap-3 rounded-t-md bg-cardGray/30 py-2 px-4 border-b-2 border-b-pureWhite/10">
        <img
          src={`${userAvatar}`}
          alt="profile"
          className="h-8 w-8 rounded-full"
        />
        <div className="text-textGray pr-8">
          <div className="text-p2">{userName}</div>
          <div className="text-p3"> {userEmail} </div>
        </div>
      </div>
      <div className="text-p2">
        <div
          className="px-4 py-2 hover:bg-pureWhite/10 cursor-pointer"
          onClick={() => [navigate(routes.PROFILE), setOpen(false)]}
        >
          <div className="flex gap-2 items-center ">
            <img
              src={theme === "dark" ? ProfileDark : ProfileLight}
              alt="profile"
              className="w-4 h-4"
            />
            <div>Profile</div>
          </div>
        </div>
        <div
          className="px-4 py-2 hover:bg-pureWhite/20 cursor-pointer"
          onClick={handleSignOut}
        >
          <div className="flex gap-2 items-center">
            <img
              src={theme === "dark" ? SignOutDark : SignOutLight}
              alt="sign-out"
              className="w-4 h-4"
            />
            <div>{data && data.status ? "Sign out" : "SignIn"} </div>
          </div>
        </div>
      </div>
    </div>
  );
};
