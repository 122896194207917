import { appContext } from "layouts";
import { useContext, useMemo } from "react";

interface InputProps {
  name?: any;
  type?: any;
  onChange?: any;
  values?: any;
  onBlur?: any;
  placeholder?: string;
  onKeyDown?: any;
  defaultValue?: any;
  touches?: any;
  errors?: any;
  disabled?: any;
  className?: string;
}

export const Input: React.FC<InputProps> = ({
  name,
  type = "text",
  onChange,
  values,
  onBlur,
  placeholder,
  onKeyDown,
  defaultValue,
  touches,
  errors,
  disabled,
}) => {
  const { theme }: any = useContext(appContext);
  const isInvalid = useMemo(() => {
    return touches?.[name] && errors?.[name];
  }, [touches, errors, name]);
  return (
    <div>
      <input
        id={name}
        name={name}
        type={type}
        onChange={onChange}
        value={values?.[name]}
        onBlur={onBlur}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        defaultValue={defaultValue}
        className={`flex items-center px-2 pl-4 py-2 md:py-3 border ${
          theme === "dark"
            ? "bg-black/50 border-white/30 text-gray-50"
            : "bg-white"
        } rounded-md text-xs w-full h-fit`}
        disabled={disabled}
      />
      {isInvalid ? (
        <div
          style={{ color: "#ef4444" }}
          className="font-semibold text-[10px] tracking-wider"
        >
          {errors[name]}
        </div>
      ) : null}
    </div>
  );
};
