import { Button, Input } from "components/shared";
import { FormikProps, withFormik } from "formik";
import React from "react";
import { object, string } from "yup";

interface ISubscriptionProps {
  onSubmit?: any;
}

interface IFormValue {
  email: any;
}

function Subscription(props: FormikProps<IFormValue> & ISubscriptionProps) {
  return (
    <div className="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 w-full md:w-[50%]">
      <div className="text-p1 md:text-h4 font-poppinsSemiBold tracking-wider whitespace-nowrap">
        News Letter
      </div>
      <form onSubmit={props.handleSubmit}>
        <div className="flex gap-2 w-full">
          <div className="w-full">
            <Input
              name="email"
              type="email"
              onChange={props.handleChange}
              values={props.values}
              onBlur={props.handleBlur}
              placeholder="Enter Email"
              errors={props.errors}
              touches={props.touched}
            />
          </div>
          <Button
            variant="medium"
            type="submit"
            title={props.isSubmitting ? "Subscribing" : "Subscribe"}
            disabled={props.isSubmitting}
          />
        </div>
      </form>
    </div>
  );
}

export default withFormik<ISubscriptionProps, IFormValue>({
  validationSchema: object().shape({
    email: string()
      .email("Invalid email format")
      .required("Password is required"),
  }),
  mapPropsToValues: () => ({
    email: "",
  }),
  handleSubmit: (values, { props, ...actions }) => {
    props.onSubmit(values, actions);
  },
})(Subscription);
