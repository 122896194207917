import React, { useContext, useEffect, useRef, useState } from "react";
import { appContext } from "layouts/layout";
import { Menu, NavBar, Notification, ProfileMenu } from "templates/Header";
import { useNavigate } from "react-router-dom";
import logo from "assets/images/header/logo.svg";
import BucketDark from "assets/images/header/bucket.svg";
import BucketLight from "assets/images/header/bucket-light.svg";
import ThemeDark from "assets/images/header/theme.svg";
import ThemeLight from "assets/images/header/theme-light.svg";
import MenuLight from "assets/images/header/menu-light.svg";
import MenuDark from "assets/images/header/menu-dark.svg";
import NotificationDark from "assets/images/header/notification-dark.svg";
import NotificationLight from "assets/images/header/notification-light.svg";
import SampleProfileDark from "assets/images/header/sample-profiledark.svg";
import SampleProfile from "assets/images/header/sample-profile.svg";
import { Modal } from "components/shared";
import Login from "templates/Auth/Login";
import { useLogin } from "hooks";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useRegister } from "hooks/Register";
import SignUp from "templates/Auth/SignUp";
import { useClickOutside } from "primereact/hooks";
import { isAuthorized as useAuthorized } from "store/auth/selector";

export const Header: React.FC = () => {
  const { onLogin } = useLogin();
  const { onRegister } = useRegister();
  const navigate = useNavigate();
  const { theme, setTheme }: any = useContext(appContext);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);
  const [authOpen, setAuthOpen] = useState<boolean>(false);
  const [authOpenQuery, setAuthOpenQuery] = useState<string>("");

  const overlayRef = useRef(null);
  useClickOutside(overlayRef, () => {
    setNotificationOpen(false);
    setMenuOpen(false);
  });
  const changeTheme = () => {
    if (theme === "dark") {
      setTheme("light");
    } else {
      setTheme("dark");
    }
  };

  const isAuthorized = useSelector(useAuthorized);
  const { auth } = useSelector((state: RootState) => state.auth);
  const { storeCart, cart } = useSelector((state: RootState) => state.cart);
  const bucketList = isAuthorized ? cart : storeCart;

  useEffect(() => {
    setAuthOpen(false);
    setAuthOpenQuery("");
  }, [auth]);

  const userAvatar =
    auth && auth.user && auth.user.avatar
      ? auth.user.avatar
      : theme === "dark"
      ? SampleProfileDark
      : SampleProfile;

  return (
    <div className="md:pt-2 md:px-12">
      <div className="h-[3.5rem] w-full bg-headerBg/5 backdrop-blur-3xl border-b border-b-pureWhite/10 md:rounded-lg drop-shadow-xl drop-shadow-pureBlack flex justify-between items-center px-4 md:px-4 z-50">
        <img src={logo} alt="logo" className="w-[7rem] lg:w-[8rem]" />
        <div className="block md:hidden">
          <div className="flex gap-4 items-center ">
            <div className="relative">
              <img
                src={theme === "dark" ? NotificationDark : NotificationLight}
                alt="notification"
                className="w-4 cursor-pointer z-20"
                onClick={() => setNotificationOpen(!notificationOpen)}
              />
              <div className="absolute z-10 -top-2 -right-2 w-4 h-4 rounded-full bg-red-600 flex justify-center items-center text-p3 text-white">
                1
              </div>
              {notificationOpen && (
                <Notification theme={theme} setOpen={setNotificationOpen} />
              )}
            </div>
            <div className="relative">
              <img
                src={theme === "dark" ? BucketDark : BucketLight}
                alt="bucket"
                className="w-5 cursor-pointer z-20"
                onClick={() => navigate("/orders/summary")}
              />
              {bucketList?.length > 0 && (
                <div className="absolute z-10 -top-2 -right-2 w-4 h-4 rounded-full bg-red-600 flex justify-center items-center text-p3 text-white">
                  {bucketList?.length}
                </div>
              )}
            </div>
            <div
              className="relative"
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
              ref={overlayRef}
            >
              <img
                src={theme === "dark" ? MenuDark : MenuLight}
                alt="menu"
                className="w-4 cursor-pointer"
              />
              {menuOpen && <Menu data={auth} setOpen={setMenuOpen} />}
            </div>
          </div>
        </div>
        <div className="hidden md:block">
          <div className="flex items-center gap-4 text-textGray text-p2">
            <div className="flex items-center gap-4">
              <div>
                <NavBar />
              </div>
              <img
                src={theme === "dark" ? ThemeDark : ThemeLight}
                alt="theme"
                className="w-5 cursor-pointer"
                onClick={() => changeTheme()}
              />
              <div
                className="relative"
                onClick={() => setNotificationOpen(!notificationOpen)}
                ref={overlayRef}
              >
                <img
                  src={theme === "dark" ? NotificationDark : NotificationLight}
                  alt="notification"
                  className="w-4 cursor-pointer z-20"
                />
                <div className="absolute z-10 -top-2 -right-2 w-4 h-4 rounded-full bg-red-600 flex justify-center items-center text-p3 text-white">
                  1
                </div>
                {notificationOpen && (
                  <Notification theme={theme} setOpen={setNotificationOpen} />
                )}
              </div>
              <div
                className="relative"
                onClick={() => navigate("/orders/summary")}
              >
                <img
                  src={theme === "dark" ? BucketDark : BucketLight}
                  alt="bucket"
                  className="w-5 cursor-pointer z-20"
                />
                {bucketList?.length > 0 && (
                  <div className="absolute z-10 -top-2 -right-2 w-4 h-4 rounded-full bg-red-600 flex justify-center items-center text-p3 text-white">
                    {bucketList?.length}
                  </div>
                )}
              </div>
              <div className="h-6 w-[1.5px] bg-textGray/80 rounded-full hidden md:block"></div>
              <div
                className="relative"
                onClick={() => setProfileMenuOpen(!profileMenuOpen)}
              >
                <img
                  src={`${userAvatar}`}
                  alt="email"
                  className="w-6 md:w-8 h-6 md:h-8 rounded-full cursor-pointer"
                />
                {profileMenuOpen && (
                  <div className="absolute top-10 right-0">
                    <ProfileMenu
                      data={auth}
                      theme={theme}
                      setOpen={setProfileMenuOpen}
                      setAuthOpen={setAuthOpen}
                      setAuthOpenQuery={setAuthOpenQuery}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={authOpen}
        setISOpen={setAuthOpen}
        children={
          authOpen === true && authOpenQuery === "login" ? (
            <Login onSubmit={onLogin} setAuthOpenQuery={setAuthOpenQuery} />
          ) : authOpen === true && authOpenQuery === "signup" ? (
            <SignUp onSubmit={onRegister} setAuthOpenQuery={setAuthOpenQuery} />
          ) : null
        }
      />
    </div>
  );
};
