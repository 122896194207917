//client routes
export const HOME = "/";
export const MENU = "/menu";
export const RESTAURANT = "/restaurant";
export const PROFILE = "/profile";
export const TERMS = "/terms-and-conditions";
export const ORDERS = "/orders";
export const ORDER_DETAIL = "/orders/detail";
export const ORDER_SUMMARY = "/orders/summary";
export const CHECKOUT = "/orders/checkout";
export const CONTACTUS = "/contact-us";
