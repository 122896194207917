import React, { useContext } from "react";
import { Dialog } from "primereact/dialog";
import CloseLight from "assets/images/header/close-light.svg";
import CloseDark from "assets/images/header/close-dark.svg";
import { appContext } from "layouts";

interface IModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  setISOpen: any;
}

export const Modal: React.FC<IModalProps> = ({
  isOpen,
  setISOpen,
  children,
}) => {
  const { theme }: any = useContext(appContext);

  const renderHeader = () => {
    return (
      <div className="flex justify-end items-center h-10 px-4">
        <img
          src={theme === "dark" ? CloseDark : CloseLight}
          alt="close"
          className="w-4 cursor-pointer"
          onClick={() => setISOpen(false)}
        />
      </div>
    );
  };
  return (
    <div>
      <Dialog
        visible={isOpen}
        onHide={() => setISOpen(false)}
        closable={false}
        header={renderHeader()}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        className={`${
          theme === "dark" ? "bg-black border-white/40" : "bg-white border-black/50"
        } border-2  rounded-lg`}
      >
        {children}
      </Dialog>
    </div>
  );
};
