import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {loginRequested} from 'store/auth/authSlice'

export function useLogin() {
  const dispatch = useDispatch();

  const onLogin = useCallback(
    async (values: any, actions: any) => {

      actions.setSubmitting(true);
      await dispatch(loginRequested(values));

      setTimeout(() => {
        actions.setSubmitting(false);
      }, 500);
    },
    [dispatch],
  );

  return {onLogin};
}
