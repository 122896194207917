import React, { useContext } from "react";
import CloseLight from "assets/images/header/close-light.svg";
import CloseDark from "assets/images/header/close-dark.svg";
import { appContext } from "layouts";
import HomeDark from "assets/images/header/home-dark.svg";
import HomeLight from "assets/images/header/home-light.svg";
import MenuDark from "assets/images/header/menupage-dark.svg";
import MenuLight from "assets/images/header/menupage-light.svg";
import OrderDark from "assets/images/header/order-dark.svg";
import OrderLight from "assets/images/header/order-light.svg";
import ProfileLight from "assets/images/header/profile-light.svg";
import ProfileDark from "assets/images/header/profile-dark.svg";
import SignOutLight from "assets/images/header/signout-light.svg";
import SignOutDark from "assets/images/header/signout-dark.svg";
import SampleProfile from "assets/images/header/sample-profile.svg";
import ThemeDark from "assets/images/header/theme.svg";
import ThemeLight from "assets/images/header/theme-light.svg";
import { useNavigate } from "react-router-dom";
import { Modal } from "components/shared";
import { useRegister } from "hooks/Register";
import SignUp from "templates/Auth/SignUp";
import Login from "templates/Auth/Login";
import { useLogin } from "hooks";

interface IMenuProps {
  setOpen: any;
  data: any;
}

export const Menu: React.FC<IMenuProps> = ({ setOpen, data }) => {
  const changeTheme = () => {
    if (theme === "dark") {
      setTheme("light");
    } else {
      setTheme("dark");
    }
  };
  const { onLogin } = useLogin();
  const { onRegister } = useRegister();
  const navigate = useNavigate();
  const {
    theme,
    setTheme,
    signupOpen,
    setSignupOpen,
    loginOpen,
    setLoginOpen,
  }: any = useContext(appContext);
  const handleSignOut = () => {
    if (data !== null) {
      if (data.status) {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
      }
    } else {
      setLoginOpen(true);
    }

    setOpen(false);
  };
  return (
    <div className="absolute z-50 top-8 right-0 bg-pureBlack border border-pureWhite/30 p-2">
      <div className="flex justify-end pb-2">
        <img
          src={theme === "dark" ? CloseDark : CloseLight}
          alt="close"
          className="w-3 cursor-pointer"
          onClick={() => setOpen(false)}
        />
      </div>
      <div className="px-2 text-p2 space-y-1">
        <div className="flex items-center gap-3 rounded-md bg-cardGray/30 py-2 px-4 border-b-2 border-b-pureWhite/10">
          <img
            src={SampleProfile}
            alt="profile"
            className="h-8 w-8 rounded-full"
          />
          <div className="text-textGray pr-8">
            <div className="text-p2">Krishz Kara</div>
            <div className="text-p3">krishzkara27@gmail.com</div>
          </div>
        </div>
        <div className="px-4 py-2 hover:bg-pureWhite/10 cursor-pointer">
          <div
            className="flex gap-4 items-center"
            onClick={() => navigate("/")}
          >
            <img
              src={theme === "dark" ? HomeDark : HomeLight}
              alt="profile"
              className="w-3.5 pb-1"
            />
            <div>Home</div>
          </div>
        </div>
        <div className="px-4 py-2 hover:bg-pureWhite/10 cursor-pointer">
          <div
            className="flex gap-4 items-center"
            onClick={() => navigate("/menu")}
          >
            <img
              src={theme === "dark" ? MenuDark : MenuLight}
              alt="menu"
              className="w-3.5 pb-1"
            />
            <div>Menu</div>
          </div>
        </div>
        <div className="px-4 py-2 hover:bg-pureWhite/10 cursor-pointer">
          <div
            className="flex gap-4 items-center"
            onClick={() => navigate("/orders")}
          >
            <img
              src={theme === "dark" ? OrderDark : OrderLight}
              alt="orders"
              className="w-3.5 pb-1"
            />
            <div>Orders</div>
          </div>
        </div>
        <div className="px-4 py-2 hover:bg-pureWhite/10 cursor-pointer">
          <div
            className="flex gap-4 items-center"
            onClick={() => navigate("/profile")}
          >
            <img
              src={theme === "dark" ? ProfileDark : ProfileLight}
              alt="profile"
              className="w-3.5 pb-1"
            />
            <div>Profile</div>
          </div>
        </div>
        <div
          className="px-4 py-2 hover:bg-pureWhite/10 cursor-pointer"
          onClick={handleSignOut}
        >
          <div className="flex gap-4 items-center">
            <img
              src={theme === "dark" ? SignOutDark : SignOutLight}
              alt="signout"
              className="w-3.5 pb-1"
            />
            <div>{data && data.status ? "Sign out" : "SignIn"}</div>
          </div>
        </div>
        <div className="px-4 py-2 cursor-pointer flex justify-between">
          <div
            className="flex gap-4 items-center"
            onClick={() => changeTheme()}
          >
            <img
              src={theme === "dark" ? ThemeDark : ThemeLight}
              alt="profile"
              className="w-3.5 pb-1"
            />
            <div>Dark Mode</div>
          </div>
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              value=""
              className="sr-only peer"
              checked={theme === "dark"}
              onChange={changeTheme}
            />
            <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-green-100 dark:peer-focus:ring-green-900 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-green-800"></div>
          </label>
        </div>
      </div>
      <Modal isOpen={loginOpen} setISOpen={setLoginOpen}>
        <Login onSubmit={onLogin} />
      </Modal>
      <Modal isOpen={signupOpen} setISOpen={setSignupOpen}>
        <SignUp onSubmit={onRegister} />
      </Modal>
    </div>
  );
};
