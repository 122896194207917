import { takeLatest, put, call, takeEvery } from "redux-saga/effects";
import { axiosInstance, apiURL } from "config";
import toast from "react-hot-toast";
import { productList } from "constants/mock";
import {
  loadProductRequested,
  loadProductSuccess,
  loadProductFail,
  loadCategorySuccess,
  loadCategoryFail,
  loadCategoryRequested,
  loadLandingOfferProductsRequested,
  loadLandingOfferProductsSuccess,
  loadLandingOfferProductsFail,
  loadAdvertisementsRequested,
  loadAdvertisementsSuccess,
  loadAdvertisementsFail,
} from "./productSlice";

export function* loadCategoryEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.get,
      `${apiURL}/get-categories`,
      action.payload
    );
    yield put(loadCategorySuccess(data));
  } catch (error: any) {
    yield put(loadCategoryFail(error.message));
  }
}

export function* loadProductEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.get,
      `${apiURL}/get-items-withsubcategory-bycategoryid?Id=${action.payload}`,
      action.payload
    );
    yield put(loadProductSuccess(data));
  } catch (error: any) {
    yield put(loadProductFail(error.message));
  }
}

export function* loadLandingOfferProductsEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.get,
      `${apiURL}/get-landing-offer-item`,
      action.payload
    );
    yield put(loadLandingOfferProductsSuccess(data));
  } catch (error: any) {
    yield put(loadLandingOfferProductsFail(error.message));
  }
}

export function* loadAdvertisementsEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const data = productList;
    yield put(loadAdvertisementsSuccess(data));
  } catch (error: any) {
    yield put(loadAdvertisementsFail(error.message));
  }
}

export function* productSaga(): Generator<any, void, any> {
  yield takeEvery(loadCategoryRequested, loadCategoryEffect);
  yield takeEvery(loadProductRequested, loadProductEffect);
  yield takeEvery(
    loadLandingOfferProductsRequested,
    loadLandingOfferProductsEffect
  );
  yield takeEvery(loadAdvertisementsRequested, loadAdvertisementsEffect);
}
