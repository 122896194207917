export const userInfo = {
  name: "Krishz Kara",
  mobile: "0765705081",
  dateOfBirth: "2000-11-29T00:00:00",
  email: "krishzkara29@gmail.com",
};

export const productList = [
  {
    subCategoryId: 1,
    name: "Chicken Fries",
    description: "",
    products: [
      {
        id: 11,
        name: "Chicken fries",
        description:
          "hot crispy Utilities for controlling how an element handles content that is too large for the container. ",
        actualPrice: 15.0,
        discount: 2.75,
        offerPrice: 12.25,
        image: "images/media1",
      },
      {
        id: 12,
        name: "Chicken BBQ",
        description: "very crispy",
        actualPrice: 37.0,
        discount: 9.5,
        offerPrice: 27.5,
        image: "images/media1",
      },
      {
        id: 13,
        name: "Chicken Popcorn",
        description: "very crispy",
        actualPrice: 27.0,
        discount: 12.8,
        offerPrice: 14.2,
        image: "images/media1",
      },
      {
        id: 14,
        name: "Chicken Pakoda",
        description: "very crispy",
        actualPrice: 37.0,
        discount: 9.5,
        offerPrice: 27.5,
        image: "images/media1",
      },
      {
        id: 15,
        name: "Chicken Pop",
        description: "very crispy",
        actualPrice: 27.0,
        discount: 12.8,
        offerPrice: 14.2,
        image: "images/media1",
      },
    ],
  },
];
