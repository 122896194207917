import { takeLatest, put, call, takeEvery } from "redux-saga/effects";
import { axiosInstance, apiURL, authURL } from "config";
import toast from "react-hot-toast";
import {
  storeSubscriptionRequested,
  storeSubscriptionSuccess,
  storeSubscriptionFail,
} from "./subscriptionSlice";

function* storeSubscriptionEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.post,
      `${authURL}/create-subcriptor`,
      action.payload
    );

    if (data?.succeeded) {
      yield put(storeSubscriptionSuccess(data));
      toast.success("Subscription Succeed");
    } else {
      if (data?.errors) {
        toast.error(data?.errors[0]);
      } else {
        toast.error("Subscription Failed");
      }
    }
  } catch (error: any) {
    yield put(storeSubscriptionFail(error.message));
  }
}

export function* subscriptionSaga(): Generator<any, void, any> {
  yield takeEvery(storeSubscriptionRequested, storeSubscriptionEffect);
}