import React, { useContext } from "react";
import SelectComponent from "react-select";
import { appContext } from "layouts";
import "styles/index.css"

interface ISelectProps {
  name: string;
  values?: any;
  placeholder?: any;
  options?: any;
  onChange?: any;
  getOptionLabel?: any;
  getOptionValue?: any;
  disabled?: any;
  errors?: any;
  touches?: any;
  onBlur?: any;
  onKeyDown?: any;
  backgroundColor?: any;
  defaultValue?: any;
}

export function Select(props: ISelectProps) {
  const { theme }: any = useContext(appContext);
  const textColor = theme === "dark" ? "#000000" : "#ffffff";
  return (
    <div className="flex flex-col gap-3 border border-pureWhite/30 hover:border-pureWhite/60 rounded">
      <SelectComponent
        // isClearable
        isSearchable
        placeholder={props.placeholder}
        options={props.options}
        defaultValue={props?.values?.[props?.name]}
        name={props.name}
        onChange={props.onChange}
        getOptionLabel={(option: any) => option[props.getOptionLabel]}
        getOptionValue={(option: any) => option[props.getOptionValue]}
        onKeyDown={props?.onKeyDown}
        isDisabled={props.disabled}
        theme={(themes: any) => ({
          ...themes,
          borderRadius: 5,
        })}
        className={`text-xs`}
        styles={{
          control: () => ({
            display: "flex",
            backgroundColor: theme === "dark" ? "#000000" : "#ffffff",
            borderRadius: "15px",
            height: "30px",
          }),
          placeholder: (provided: any) => ({
            ...provided,
            color: theme === "dark" ? "#ffffff" : "#000000",
          }),
          singleValue: (provided: any) => ({
            ...provided,
            color: theme === "dark" ? "#ffffff" : "#000000",
          }),
          menu: (provided: any) => ({
            ...provided,
            backgroundColor: theme === "dark" ? "#000000" : "#ffffff",
            borderColor: theme === "dark" ? "#ffffff" : "#000000",
          }),
          menuList: (provided: any) => ({
            ...provided,
            backgroundColor: theme === "dark" ? "#000000" : "#ffffff",
            border: `1px solid ${theme === "dark" ? "#ffffff" : "#000000"}`,
            borderRadius: "4px",
            maxHeight: "200px",
            overflowY: "auto",
          }),
          option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused
              ? theme === "dark"
                ? "#333333"
                : "#f0f0f0"
              : "transparent",
            color: state.isFocused
              ? theme === "dark"
                ? "#ffffff"
                : "#000000"
              : "#888888",
          }),
        }}
        classNamePrefix="custom-select"
      />
    </div>
  );
}
