import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer, PURGE } from "redux-persist";
import authReducer from "store/auth/authSlice";
import productReducer from "store/products/productSlice";
import userReducer from "store/user/userSlice";
import cartReducer from "store/cart/cartSlice";
import orderReducer from "store/order/orderSlice";
import { persistConfig, cartConfig, orderConfig } from "persist";
import { subscriptionSaga } from "./subscription/sagas";
import subscriptionReducer from "store/subscription/subscriptionSlice";

const appReducer = combineReducers({
  auth: persistReducer(persistConfig, authReducer),
  cart: persistReducer(cartConfig, cartReducer),
  order: persistReducer(orderConfig, orderReducer),
  product: productReducer,
  user: userReducer,
  subscription: subscriptionReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === PURGE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
