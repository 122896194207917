import React, { useContext } from "react";
import { appContext } from "layouts/layout";
import loader from "assets/images/home/loader.json";
import Lottie from "react-lottie";

interface ILoadingProps {
  animation?: string;
}

export const Loading: React.FC = (props: ILoadingProps) => {
  const { theme }: any = useContext(appContext);
  return (
    <div
      className={`${
        theme === "dark" ? "bg-black" : "bg-white"
      } w-full h-screen flex justify-center items-center`}
    >
      <div className="w-72 h-1/12">
        <Lottie
          options={{
            animationData: props?.animation ?? loader,
          }}
        />
      </div>
    </div>
  );
};
