import storage from "redux-persist/lib/storage";

export const persistConfig = {
  key: "root",
  storage,
};

export const cartConfig = {
  key: "cart",
  storage,
};

export const orderConfig = {
  key: "orderType",
  storage,
  version: 1, 
  whitelist: ["orderType", "guest"],
};


