import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { googleLoginRequested } from "store/auth/authSlice";

export function useGoogleSignin() {
  const dispatch = useDispatch();

  const onGoogleSignin = useCallback(
    async (values: any, actions: any) => {
      actions.setSubmitting(true);
      await dispatch(googleLoginRequested(values));
      setTimeout(() => {
        actions.setSubmitting(false);
      }, 500);
    },
    [dispatch]
  );

  return { onGoogleSignin };
}
