import { put, call, takeEvery, select } from "redux-saga/effects";
import { axiosInstance, apiURL } from "config";
import { isAuthorized as useAuthorized } from "store/auth/selector";
import { selectAuth } from "store/auth/selector";

import {
  loadCartRequested,
  loadCartSuccess,
  loadCartFail,
  storeCartRequested,
  storeCartSuccess,
  storeCartFail,
  deleteCartRequested,
  deleteCartSuccess,
  deleteCartFail,
  updateCartRequested,
  updateCartSuccess,
  updateCartFail,
} from "./cartSlice";
import toast from "react-hot-toast";

export function* loadCartEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const isAuthorized = yield select(useAuthorized);

    if (isAuthorized) {
      const { data } = yield call(
        axiosInstance.get,
        `${apiURL}/get-store-cart-item?userId=${action.payload?.id}`,
        action.payload
      );
      yield put(loadCartSuccess(data));
    }
  } catch (error: any) {
    yield put(loadCartFail(error.message));
  }
}

export function* storeCartEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const isAuthorized = yield select(useAuthorized);

    if (isAuthorized) {
      const { data } = yield call(
        axiosInstance.post,
        `${apiURL}/add-update-store-cart`,
        action.payload
      );
      if (data?.result) {
        yield put(storeCartSuccess(data));

        yield call(loadCartEffect, {
          payload: { id: data?.result?.userId },
          type: "",
        });
      }
    } else {
      const state = yield select((state: any) => state.cart);
      const existingItem = state.storeCart.some(
        (cartItem: any) => cartItem?.id === action.payload?.id
      );

      if (existingItem) {
        const updatedStoreCart = state.storeCart.map((cartItem: any) =>
          cartItem?.id === action?.payload?.id
            ? { ...cartItem, qty: cartItem?.qty + 1 }
            : cartItem
        );
        yield put(storeCartSuccess(updatedStoreCart));
      } else {
        yield put(
          storeCartSuccess([
            ...(state.storeCart || []),
            {
              ...action.payload,
              qty: 1,
            },
          ])
        );
      }
    }
  } catch (error: any) {
    yield put(storeCartFail(error.message));
  }
}

export function* deleteCartEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const auth = yield select(selectAuth);
  const isAuthorized = yield select(useAuthorized);
  try {
    if (isAuthorized) {
      const { data } = yield call(
        axiosInstance.delete,
        `${apiURL}/delete-store-cart`,
        {
          data: action.payload,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (data?.succeeded) {
        yield put(deleteCartSuccess(data));
        yield call(loadCartEffect, {
          payload: { id: auth?.auth?.user?.id },
          type: "",
        });
      } else {
        toast.error(data?.errors);
      }
    } else {
      const state = yield select((state: any) => state.cart);
      const deletedItem = state.storeCart.find(
        (cartItem: any) => cartItem?.id === action.payload
      );
      state.storeCart = state.storeCart.filter(
        (cartItem: any) => cartItem?.id !== action.payload
      );
      const updatedDeleteCart = [...(state.deleteCart || []), deletedItem];
      yield put(deleteCartSuccess(updatedDeleteCart));
    }
  } catch (error: any) {
    yield put(deleteCartFail(error.message));
  }
}

export function* updateCartEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const auth = yield select(selectAuth);

    const isAuthorized = yield select(useAuthorized);
    const quantityChange = action.payload?.type === "reduce" ? -1 : 1;

    if (isAuthorized) {
      const { data } = yield call(
        axiosInstance.post,
        `${apiURL}/add-update-store-cart`,
        action.payload?.details
      );
      if (data?.succeeded) {
        const state = yield select((state: any) => state.cart);
        for (const cartItem of state.cart) {
          if (
            cartItem?.id ===
            action.payload?.details?.userItems?.itemDetail?.itemId
          ) {
            debugger;
            if (cartItem?.qty === 1 && action.payload?.type === "reduce") {
              yield call(deleteCartEffect, {
                payload: {
                  userId: auth?.auth?.user?.id,
                  itemIds: [cartItem?.id],
                },
                type: "",
              });
              yield call(loadCartEffect, {
                payload: { id: data?.result?.userId },
                type: "",
              });
              continue;
            }
          } else {
            yield put(updateCartSuccess(data));
            yield call(loadCartEffect, {
              payload: { id: data?.result?.userId },
              type: "",
            });
          }
        }
        yield call(loadCartEffect, {
          payload: { id: data?.result?.userId },
          type: "",
        });
      }
    } else {
      const updatedCart = [];
      const state = yield select((state: any) => state.cart);

      for (const cartItem of state.storeCart) {
        if (cartItem?.id === action.payload?.id) {
          if (cartItem?.qty === 1 && action.payload?.type === "reduce") {
            yield call(deleteCartEffect, {
              payload: {},
              type: "",
            });
            continue;
          }
          updatedCart.push({
            ...cartItem,
            qty: cartItem.qty + quantityChange,
          });
        } else {
          updatedCart.push(cartItem);
        }
      }
      yield put(storeCartSuccess(updatedCart));
    }
  } catch (error: any) {
    yield put(updateCartFail(error.message));
  }
}

export function* cartSaga(): Generator<any, void, any> {
  yield takeEvery(loadCartRequested, loadCartEffect);
  yield takeEvery(storeCartRequested, storeCartEffect);
  yield takeEvery(deleteCartRequested, deleteCartEffect);
  yield takeEvery(updateCartRequested, updateCartEffect);
}
