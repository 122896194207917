import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { registerRequested } from "store/auth/authSlice";

export function useRegister() {
  const dispatch = useDispatch();

  const onRegister = useCallback(
    async (values: any, actions: any) => {
      actions.setSubmitting(true);
      await dispatch(
        registerRequested({
          firstname: values.firstname,
          lastname: values.lastname,
          password: values.password,
          email: values.email,
          mobile: values.mobile,
          userType: 1,
        })
      );
    },
    [dispatch]
  );

  return { onRegister };
}
