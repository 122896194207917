import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadUserRequested } from "store/user/userSlice";

//load user info
export function useLoadUser() {
  const dispatch = useDispatch();

  const onLoadUser = useCallback(async () => {
    await dispatch(loadUserRequested({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadUser();
  }, [onLoadUser]);

  return { onLoadUser };
}
