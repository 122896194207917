import React from "react";
import CloseDark from "assets/images/header/close-dark.svg";
import CloseLight from "assets/images/header/close-light.svg";

interface INotificationProps {
  theme: string;
  setOpen: any;
}

export const Notification: React.FC<INotificationProps> = ({
  theme,
  setOpen,
}) => {
  return (
    <div className="absolute top-8 -right-20 bg-pureBlack w-[18rem] h-[20rem] border border-pureWhite/40 rounded-lg px-4 py-2">
      <div className="flex justify-end mb-2">
        <img
          src={theme === "dark" ? CloseDark : CloseLight}
          alt="theme"
          className="w-3 cursor-pointer"
          onClick={() => setOpen(false)}
        />
      </div>
      <div className="flex">
        <div className="text-p3 text-textGray/60">29/10/2024 8.30 am</div>
      </div>
      <div className="flex gap-4 ">
        <div className="bg">your order has been successfully confirmed</div>
      </div>

      <hr className="border-pureWhite/20 mt-1" />
    </div>
  );
};
